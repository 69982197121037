import React from 'react';
import ReactDOM from 'react-dom';
import { utils } from '@screentone/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import '@screentone/core/css/index.css';
import './index.css';
import App from './App';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: utils.designTokens.ColorBlurple50,
    },
    secondary: {
      main: utils.designTokens.ColorLava50,
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
